/* SPECIAL THANKS TO MENDY BERGER */
/* LICENSED UNDER THE MIT LICENSE */
/* COPYRIGHT 2020 */

import { CircularProgress, Snackbar } from "@material-ui/core";
import React, { useState } from "react";
import Compressor from "compressorjs";
import useLongPress from "../utils/useLongPress";
import SelectUnassignedImages from "../layout/SelectUnassignedImages";

const uploaderToken = process.env.REACT_APP_FS1_UPLOAD_TOKEN;

export default function Fs1Input({ disabled = false, accept = acceptDefault, placeholder, name = "", onChange, optimize, saveOriginals, existingImages, onDoneSelecting }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [selectImagesOpen, setSelectImagesOpen] = React.useState(false);

  const openSnackbar = () => {
    setSnackbarOpen(true);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  function changeValue(v) {
    setLoading(false);
    setError(false);
    onChange(v);
  }

  const valueChanged = async (e) => {
    alert("hi");
    const files = [...e.target.files].filter((e) => e);
    if (!files.length) return;
    try {
      const optimizedPromiseArr = files.map((file) => Promise.resolve(optimize ? optimizeImg(file) : file).then((optimizedFile) => uploadFile(optimizedFile, file.name)));
      const res = await Promise.all(optimizedPromiseArr);
      changeValue(res);
      openSnackbar();
      if (optimize && saveOriginals) {
        const originalPromiseArr = files.map((file) => uploadFile(file));
        const res2 = await Promise.all(originalPromiseArr);
        saveOriginals(res2);
      }
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    input: {
      display: "none",
    },
    img: {
      display: "block",
      maxHeight: "100%",
      maxWidth: "100%",
    },
    label: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "40px",
      width: "100%",
      cursor: "pointer",
      backgroundImage: loading ? "none" : "url(/img/add-a-photo.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      boxSizing: "border-box",
      borderColor: disabled ? "#f9f9f9" : undefined,
    },
  };

  const onShortPress = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*;capture=camera";
    input.onchange = valueChanged;
    input.disabled = disabled;
    input.style = styles.input;
    input.multiple = true;
    input.click();
  };
  const onLongPress = () => {
    if (existingImages.length && onDoneSelecting) {
      setSelectImagesOpen(true);
    }
  };

  const longPressEvent = useLongPress(onLongPress, onShortPress);

  return (
    <div>
      <label style={styles.label} {...longPressEvent}>
        {loading && <CircularProgress />}

        {!loading && (
          <>
            {error && (
              <div title="Upload Failed, Please Try Selecting The File Again">
                <svg fill="#000000" height="100" viewBox="0 0 24 24" width="100" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                </svg>
              </div>
            )}
          </>
        )}
      </label>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message="Image Uploaded"
      />
      <SelectUnassignedImages open={selectImagesOpen} setOpen={setSelectImagesOpen} images={existingImages} onDone={onDoneSelecting} />
    </div>
  );
}

var acceptDefault = "application/pdf,image/tiff,image/*,.pdf,.tif,.tiff,image/bmp,image/jpeg,image/png,image/x-ms-bmp,image/x-png,.bmp,.jpg,.jpeg,.jpe,.jfif,.pjpeg,.pjp,.png";

export function optimizeImg(file, ops) {
  return new Promise(async (res, rej) => {
    if (typeof file === "string" && isValidHttpUrl(file)) {
      file = await fetch(file);
      file = await file.blob();
    }
    new Compressor(file, {
      height: 1000,
      //you can overide the default height and add more config with ops
      ...ops,
      success(result) {
        res(result);
      },
      error(err) {
        console.log(err.message);
        res(file);
      },
    });
  });
}

export function uploadFile(file, filename) {
  let body = new FormData();
  if (filename) {
    body.append("ev_file", file, filename);
  } else {
    body.append("ev_file", file);
  }
  return fetch("https://csfileserver.com/upload", {
    method: "POST",
    headers: { upload_token: uploaderToken },
    body,
  })
    .then((res) => res.json())
    .then((res) => res.path);
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
